import React, { useState } from 'react';
import styles from './WarningBanner.module.css';
import clsx from 'clsx';
import { ContentfulImage } from '@cv/webframework-react-components';
import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent, { ContentfulFile } from '@customTypes/ContentfulComponent';

import { useSelector } from 'react-redux';
import checkWarningStatus from '@utils/warning-status-checker';

import { FaChevronRight } from 'react-icons/fa';
import Dropdown from '@components/Dropdown';
import dropDownStyle from '@components/Dropdown/Dropdown.module.css';
import { WarningModal } from '@components/VehicleCondition/VehicleConditionItem';

export type WarningBannerProps = {
  name: string;
  content: Array<ContentfulComponent>;
  warningBannerLabel: string;
  theme?: string;
  dropDownContainer?: HTMLElement;
  commonWarningIcon?: ContentfulFile;
  className?: string;
  modalWarningIcon?: ContentfulFile;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
};

const WarningBanner = ({
  name,
  content,
  warningBannerLabel,
  theme,
  dropDownContainer,
  commonWarningIcon,
  className,
  modalWarningIcon,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
}: WarningBannerProps) => {
  const [isConfirmationModalVisible, toggleConfirmationModal] = useState<boolean>(false);
  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const checkWarning = checkWarningStatus(vehicleStatus?.mil);
  const firstWarningItem = content[0].content[0];

  const renderFirstItem = (): JSX.Element => {
    return (
      <span className={styles['warning-menu']}>
        {!!commonWarningIcon && (
          <ContentfulImage id="warning-icon" className={styles['warning-menu-icon']} imageData={commonWarningIcon} />
        )}
        {checkWarning > 1 ? warningBannerLabel : firstWarningItem.statusWarningLabel}
      </span>
    );
  };

  const filerWarningStatuses = () => {
    const keysToShow = vehicleStatus?.mil.reduce((result: string[], item) => {
      if (item.status === '1') result.push(item.type);
      return result;
    }, []);

    return content.map((item) => {
      const content = item.content.filter((item: ContentfulComponent) => keysToShow.includes(item.apiKey));
      return { ...item, content: content };
    });
  };

  if (!checkWarning) return null;
  if (checkWarning > 1) {
    return (
      <Dropdown
        firstItemLabel={renderFirstItem()}
        theme={theme}
        classes={{ dropdown: styles['drop-down'], container: className }}
        isContentToggle={false}
        container={dropDownContainer}
      >
        <ContentRenderer
          content={filerWarningStatuses()}
          {...{ name, scheduleServiceButtonUrl, scheduleServiceButtonLabel, modalWarningIcon, commonWarningIcon }}
        />
      </Dropdown>
    );
  }

  return (
    <div className={clsx(dropDownStyle['Dropdown'], { [dropDownStyle.dark]: theme === 'dark' })}>
      <button
        className={clsx(dropDownStyle['Dropdown-button'])}
        onClick={() => toggleConfirmationModal(!isConfirmationModalVisible)}
      >
        {renderFirstItem()}
        <FaChevronRight className={dropDownStyle['Dropdown-chevron']} />
      </button>
      <WarningModal
        icon={modalWarningIcon}
        label={firstWarningItem.label}
        description={firstWarningItem.description}
        {...{
          toggleConfirmationModal,
          isConfirmationModalVisible,
          scheduleServiceButtonUrl,
          scheduleServiceButtonLabel,
        }}
      />
    </div>
  );
};

export default WarningBanner;
