import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  SubscribedPackage,
  Product,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { ContentfulFile } from '@customTypes/ContentfulComponent';
import { FaChevronRight } from 'react-icons/fa';
import Dropdown from '@components/Dropdown';
import { useHistory } from 'react-router';

import Button from '@components/Button';
import dropDownStyle from '@components/Dropdown/Dropdown.module.css';
import PackageItem from './PackageItem';
import s from './SubscriptionInformation.module.css';
import { orderPackages } from '@utils/orderPackages';
import isPackageToShow from '@utils/isPackageToShow';
import { FlagGroup } from '@customTypes/package-flags';

export interface ProductWithServices extends Product {
  services?: VehicleServiceDetails[];
}
export interface SubscribedPackageWithServices extends SubscribedPackage {
  packageName: string;
  products: ProductWithServices[];
}

export type PackageItemContenfulProps = {
  trialSubscriptionLabel: string;
  paidSubscriptionLabel: string;
  endsLabel: string;
  startsLabel: string;
  renewsLabel: string;
  beginsLabel: string;
  activeIcon?: ContentfulFile;
  upcomingIcon?: ContentfulFile;
  infoIcon?: ContentfulFile;
  productIcon?: ContentfulFile;
  renewsMonthly: string;
  renewsAnnually: string;
  renewsTermAnuall: string;
  renewsTermMouth: string;
  currencyLabels: { usd: string; cad: string; mxn: string };
};

export type SubscriptionInformationProps = PackageItemContenfulProps & {
  name: string;
  title: string;
  dropdownTitleHasPackages: string;
  dropdownTitleNoPackages: string;
  addSubscriptionLabel: string;
  managePackageLink: string;
  className?: string;
  theme?: string;
  dropDownContainer?: HTMLElement;
  data: SubscribedPackageWithServices[];
  flagGroup?: FlagGroup;
};

const SubscriptionInformation = (props: SubscriptionInformationProps) => {
  const {
    name,
    title,
    dropdownTitleHasPackages,
    dropdownTitleNoPackages,
    managePackageLink,
    addSubscriptionLabel,
    dropDownContainer,
    className,
    theme,
    flagGroup,
    data: selectedVehiclePackages,
    ...packageItemProps
  } = props;
  const history = useHistory();

  const packagesToShow = useMemo(
    () =>
      selectedVehiclePackages.filter((pkg: SubscribedPackageWithServices) => isPackageToShow(pkg, flagGroup?.flags)),
    [selectedVehiclePackages, flagGroup],
  );

  const showAddButton = useMemo(
    () => !packagesToShow?.find((p) => p.autoRenew || new Date(p.termStartDate) > new Date(Date.now())),
    [packagesToShow],
  );

  const packageList = useMemo(
    () =>
      orderPackages<SubscribedPackageWithServices>(packagesToShow).map(
        (packageItem: SubscribedPackageWithServices, idx: number) => (
          <PackageItem
            key={packageItem.marketingName + idx}
            {...{
              packageItem,
              ...packageItemProps,
            }}
          />
        ),
      ),
    [packagesToShow, packageItemProps],
  );

  if (!packagesToShow?.length) {
    return (
      <div className={clsx(dropDownStyle['Dropdown'], { [dropDownStyle.dark]: theme === 'dark' })}>
        <button className={clsx(dropDownStyle['Dropdown-button'])} onClick={() => history.push(managePackageLink)}>
          {dropdownTitleNoPackages}
          <FaChevronRight className={dropDownStyle['Dropdown-chevron']} />
        </button>
      </div>
    );
  }
  return (
    <Dropdown
      firstItemLabel={dropdownTitleHasPackages}
      theme={theme}
      classes={{ dropdown: s.dropDown, container: className, wrapper: s.wrapper }}
      isContentToggle={false}
      container={dropDownContainer}
      hideOnLocationChange
      anchorOrigin={{ horizontal: 'center' }}
    >
      <div className={s.menuContainer}>
        <h2 className={s.title}>{title}</h2>
        <ul className={s.packageList}>{packageList}</ul>
        {showAddButton && <Button onClick={() => history.push(managePackageLink)}>{addSubscriptionLabel}</Button>}
      </div>
    </Dropdown>
  );
};

export default SubscriptionInformation;
