import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export const isSubscriptionTermMonthly = (subscription: SubscribedPackage | undefined): boolean => {
  if (!subscription) {
    return false;
  }
  const { currentTerm, currentTermUnit } = subscription;

  if (currentTermUnit === TermUnit.Months && currentTerm !== 12) {
    return true;
  }

  return false;
};
