import {
  IVehicle,
  setAccountData,
  setSubscribedServices,
  setUserPinStatus,
  setVehicleIds,
  setVehicleVins,
} from '@redux/actions';
import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../api';

import { ISxmKey } from '@api/types/token';
import { LoaderBackdrop } from '@components/Loader';
import { setPackages } from '@redux/actions/user';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import urlSearchParams, { parsePromoCodeCriteria } from '@utils/urlSearchParams';
import usePreferencesSelector from '@redux/selectors/preferences';
import useDialogActions from '@redux/hooks/dialog';
import { flowNames, SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';
import { getTokenUserAndTenant } from '@utils/token';
import ErrorModal from './ErrorModal';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';

type UserInfoProps = {
  goBack?: () => void;
  labels?: { [index: string]: { primary?: string; analyticsEventName?: string } };
};

const UserInfo = ({ goBack, labels }: UserInfoProps): JSX.Element | null => {
  const isVehiclesDataFetched = useRef(false);
  const dispatch = useDispatch();
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const { showDialog } = useDialogActions();
  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [showPrimarySubscriberError, setShowPrimarySubscriberError] = useState(false);
  const preferences = usePreferencesSelector();

  const {
    sxmKeyDetails,
    vinid: vinId,
    vehicleid: vehicleId,
    flowName,
    uri,
    p: promoCode,
    pc: promoCodeCriteriaStr,
    v: marketingVehicleId,
    vin,
  } = urlSearchParams.getAll<{
    v?: string;
    vinid?: string;
    vehicleid?: string;
    uri?: string;
    flowName?: string;
    sxmKeyDetails?: ISxmKey;
    p?: string;
    pc?: string;
    vin?: string;
  }>();
  const providedVehicleId = marketingVehicleId || sxmKeyDetails?.vehicleId;

  useEffect(() => {
    setApiStatus('loading');
    const { userId, tenantId } = getTokenUserAndTenant(api.storeService.getAccessToken());
    urlSearchParams.set('tenantId', tenantId);
    api.storeService.setUserID(userId);

    const callApi = async () => {
      if (providedVehicleId) {
        await getVehiclesData([providedVehicleId], userId);
      }

      await getAccountDetails(userId);
    };
    callApi();
  }, []);

  const getAccountDetails = async (userId: string) => {
    try {
      const { data } = await api.getAccountDetails(undefined, userId);

      if (data) {
        // we need to save email in case of promotional flow
        data?.email && urlSearchParams.set('userEmail', data.email);
        dispatch(setUserPinStatus(data?.isPinConfigured || false));
        dispatch(setAccountData(data));

        if (promoCode && promoCodeCriteriaStr) {
          const promoCodeCriteria = parsePromoCodeCriteria(promoCodeCriteriaStr);

          if (promoCodeCriteria) {
            api.storeService.setPromoCode(promoCode);
            api.storeService.setPromoCodeCriteria(promoCodeCriteria);
          }
        }

        if (!flowNames?.includes(flowName)) {
          if (
            data.primarySubscriberVehicleIds.length === 0 ||
            (providedVehicleId && !data.primarySubscriberVehicleIds.includes(providedVehicleId))
          ) {
            setShowPrimarySubscriberError(true);
            return;
          }
        }

        await getVehiclesData(data.vehicleIds, data._id, data.primarySubscriberVehicleIds);

        // it's temporary solution while searchUser shim for Canada haven't implemented yet
        const vehicle = api.storeService.getVehicleData();
        if (vehicle?.ownershipType === 'CPO' && vehicle.registrationCountry === 'CAN') {
          showDialog({
            title: preferences.errorHeaderLabel,
            message: preferences.defaultErrorMsg,
            reLogin: true,
          });
          return;
        }

        if (uri) {
          const [pathname, searchParams = location.search] = uri.split('?');
          history.push({
            pathname,
            search: searchParams,
          });
        } else {
          history.push('/');
        }
        setApiStatus('idle');
      }
    } catch (error) {
      if (error.status === HttpStatus.FORBIDDEN) {
        if (flowNames?.includes(flowName)) {
          history.push(SUBSCRIPTION_PAGE_URL);
        } else {
          setShowPrimarySubscriberError(true);
        }
      }

      console.error('sxm account error', error);
      setApiStatus('error');
    }
  };

  const getVehiclesData = async (vehicleIds: string[], userId: string, primaryVehicleIds?: string[]) => {
    if (isVehiclesDataFetched.current) {
      return;
    }

    dispatch(setVehicleIds(vehicleIds));

    const correctVehicleIds = primaryVehicleIds || vehicleIds;

    const _subscribed = await api.getVinSubscribedPackages(correctVehicleIds, userId);
    dispatch(setPackages(_subscribed));
    dispatch(setSubscribedServices(_subscribed));
    await getVinStatus(correctVehicleIds);

    isVehiclesDataFetched.current = true;
  };

  const getVinStatus = async (vehicleIds: string[]) => {
    try {
      const vehiclesData: IVehicle[] = await api.getVehiclesData(vehicleIds);
      dispatch(setVehicleVins(vehiclesData));
      const _selectVehicle = vehiclesData?.find(
        (vehicle: IVehicle) => vehicle?.vin?.includes(getVin()) || vehicle?.vehicleId?.includes(getVehicleId()),
      );
      api.storeService.setVehicleData(_selectVehicle || vehiclesData[0]);
    } catch (error) {
      console.error('sxm vehicle vin error', error);
    }
  };

  const getVehicleId = (): string => sxmKeyDetails?.vehicleId || marketingVehicleId || vehicleId || ' ';
  const getVin = (): string => vinId || vin || ' ';

  // ------------------- MODAL ---------------------
  const handleCloseModal = () => {
    setApiStatus('idle');
    goBack?.();
  };

  const handleCancel = () => {
    history.push({ pathname: '/login' });
  };

  if (showPrimarySubscriberError) {
    // TODO: combine all PS role error dialogs in one place
    return (
      <ErrorModal
        errorMessage={preferences.noLinkedVehiclesErrorMessage ?? preferences.notLinkedVehicleErrorMessage}
        okLabel={preferences.ownerPortalButtonLabel}
        onOk={() => {
          window.location.replace(preferences.ownerPortalLink);
        }}
      />
    );
  }

  if (apiStatus === 'error' && goBack && labels) {
    return (
      <ErrorModal
        analyticsEventName={labels.userInfoErrorModalBody.analyticsEventName}
        errorMessage={`${labels.userInfoErrorModalBody.primary} ${preferences.customerCareNumber}`}
        onClose={handleCloseModal}
        onOk={handleCloseModal}
        onCancel={handleCancel}
      />
    );
  }

  // -------------------------------------------------

  return apiStatus === 'loading' ? <LoaderBackdrop /> : null;
};

export default UserInfo;
