import isEqual from 'lodash/isEqual';

import { UserByIdResponse, Vehicles } from '@cv/portal-idm-lib/user/user-Info/models';
import { UserCreateResponse } from '@cv/portal-idm-lib/user/user-create/models';
import { UserUpdateResponse } from '@cv/portal-idm-lib/user/user-update-profile/models';
import { PatchPayload } from '@cv/portal-idm-lib/contact/models/patch';
import { UpdateOperation } from '@cv/portal-idm-lib/user/user-update-profile/enums';
import { VehicleContactResponse, ContactResponse, ManagedContact } from '@cv/portal-idm-lib/contact/models';
import { PaymentMethodResponse, UpdatePaymentMethodPayload } from '@cv/portal-cps-lib/payment/payment-methods/models';
import { removedFieldMarker, markRemoved, isMarkedRemoved } from '@utils/form-removal-utils';
import {
  AccountData,
  ContactData,
  ContactFormData,
  AccountFormData,
  ContactFilter,
  PaymentMethodData,
  ServiceData,
} from '../types/';
import { ContactStatusTypes } from '@cv/portal-idm-lib/contact/enums';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import vehicle from '@redux/reducers/vehicle';
import { EffectiveRoles, RoleType } from '@cv/portal-idm-lib/models';

// This is obv Spanish-only and we should figure out a better way to do this in the future
export const getPhoneTypeShortcut = (type?: string) => {
  switch (type?.toLowerCase()) {
    case 'mobile':
      return '(m)';
    case 'home':
      return '(h)';
    case 'work':
      return '(w)';
    case 'other':
      return '(o)';
  }
};

type customFields = {
  customField01?: string;
  customField02?: string;
  customField03?: string;
  customField04?: string;
  customField05?: string;
  customFieldLabel01?: string;
  customFieldLabel02?: string;
  customFieldLabel03?: string;
  customFieldLabel04?: string;
  customFieldLabel05?: string;
  customBlob01?: string; // JSON
};

type updatedAccountData = AccountData & {
  customFields: {
    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
    label1?: string;
    label2?: string;
    label3?: string;
    label4?: string;
    label5?: string;
    blob?: string;
  };
  primarySubscriberVehicleIds: string[];
};

type UserDataFormatter = (
  data: (UserByIdResponse | UserCreateResponse | UserUpdateResponse) & customFields,
) => updatedAccountData;

export const formatUserData: UserDataFormatter = (data) => ({
  title: data.title,
  name: data.userName,
  tenantId: data.tenantId,
  email: data.email,
  preferredTimeZone: data.preferredTimeZone,
  billingCountry: data.billingCountry,
  mailingCountry: data.billingCountry,
  isPinConfigured: data.isPinConfigured,
  externalTenantUserId: data.externalTenantUserId,
  primaryPhone: {
    number: data.primaryPhone,
    type: data.primaryPhoneType,
    typeShortcut: getPhoneTypeShortcut(data.primaryPhoneType),
  },
  secondaryPhone: {
    number: data.secondaryPhone,
    type: data.secondaryPhoneType,
    typeShortcut: getPhoneTypeShortcut(data.secondaryPhoneType),
  },
  homeAddress: {
    street: data.billingPostalAddress1,
    unit: data.billingPostalAddress2,
    city: data.billingCity,
    state: data.billingStateProvince,
    zip: data.billingPostalCode,
  },
  billingAddress: {
    street: data.billingPostalAddress1,
    unit: data.billingPostalAddress2,
    city: data.billingCity,
    state: data.billingStateProvince,
    zip: data.billingPostalCode,
  },
  mailingAddress: {
    street: data.mailingPostalAddress1,
    unit: data.mailingPostalAddress2,
    city: data.mailingCity,
    state: data.mailingStateProvince,
    zip: data.mailingPostalCode,
  },
  homeAddressSameAsMailingAddress: isEqual(
    [
      data.billingPostalAddress1,
      data.billingPostalAddress2,
      data.billingCity,
      data.billingStateProvince,
      data.billingPostalCode,
    ],
    [
      data.mailingPostalAddress1,
      data.mailingPostalAddress2,
      data.mailingCity,
      data.mailingStateProvince,
      data.mailingPostalCode,
    ],
  ),
  userName: {
    givenName: data.givenName,
    fathersName: data.surname,
    mothersName: data.surname2,
  },
  primarySubscriberVehicleIds: (data.effectiveRoles as EffectiveRoles[])?.reduce<string[]>(
    (vehicleIds, { refProperties }) =>
      refProperties.roleType === RoleType.PRIMARY_SUBSCRIBER && refProperties.vehicleId
        ? [...vehicleIds, ...refProperties.vehicleId]
        : vehicleIds,
    [],
  ),
  notificationPreferences: {
    email: (data.vehicles as Vehicles[])?.reduce((email: string, vehicle: Vehicles) => {
      if (!email) {
        const role = vehicle.refProperties?.roles.find((r) => r.schema?.content?.email?.find((e) => e.value));
        if (role) {
          email = role.schema?.content?.email?.find((e) => e.value)?.value || '';
        }
      }

      return email;
    }, ''),
    phone: (data.vehicles as Vehicles[])?.reduce((phone: string, vehicle: Vehicles) => {
      if (!phone) {
        const role = vehicle.refProperties?.roles.find((r) => r.schema?.content?.text?.find((t) => t.phone));
        if (role) {
          phone = role.schema?.content?.text?.find((t) => t.phone)?.phone || '';
        }
      }

      return phone;
    }, ''),
    services: data.vehicles
      ?.flatMap((vehicle: Vehicles) =>
        vehicle.refProperties?.roles.flatMap((role) => role.schema?.content?.preferences),
      )
      .filter(Boolean) as ServiceData[],
  },
  vehicles: data.vehicles,
  roles: data.roles,
  vehicleIds:
    data?.roles
      ?.map((e: { refProperties: { vehicleId: string } }) => e.refProperties.vehicleId)
      ?.flat()
      ?.filter((item: string, i: number, ar: Array<string>) => item && ar.indexOf(item) === i) || [],
  _id: data._id,
  billingId: data.billingId,
  customFields: {
    field1: data.customField01,
    field2: data.customField02,
    field3: data.customField03,
    field4: data.customField04,
    field5: data.customField05,
    label1: data.customFieldLabel01,
    label2: data.customFieldLabel02,
    label3: data.customFieldLabel03,
    label4: data.customFieldLabel04,
    label5: data.customFieldLabel05,
    blob: data.customBlob01,
  },
  preferredLanguage: data.preferredLanguage,
});

export const formatUserFormData = (formData: AccountFormData) => {
  const result: Partial<AccountFormData> = { ...formData };
  if (isMarkedRemoved(formData.secondaryPhone)) {
    result.secondaryPhoneType = markRemoved(formData.secondaryPhoneType);
  }
  if (formData.mailingAddressSameAsHome) {
    result.mailingPostalAddress1 = result.billingPostalAddress1;
    result.mailingPostalAddress2 = result.billingPostalAddress2 || markRemoved(formData.mailingPostalAddress2);
    result.mailingCity = result.billingCity;
    result.mailingStateProvince = result.billingStateProvince;
    result.mailingPostalCode = result.billingPostalCode;
  }
  result.billingCountry ??= get('defaultCountry', 'MEX') as string;
  result.mailingCountry ??= result.billingCountry;
  delete result.name;
  delete result.mailingAddressSameAsHome;

  return result;
};

type UpdateFormatter = (data: Record<string, string>) => PatchPayload[];

export const formatUpdateData: UpdateFormatter = (data) => {
  console.log(data);
  return Object.keys(data)
    .filter((key) => key !== '_id' && typeof data[key] !== 'undefined')
    .map((key) =>
      isMarkedRemoved(data[key])
        ? {
            operation: UpdateOperation.Remove,
            field: key,
            value: data[key].split(removedFieldMarker)[0],
          }
        : {
            operation: UpdateOperation.Replace,
            field: key,
            value: data[key],
          },
    );
};

type ContactDataFormatter = (data: ContactResponse) => ContactData;

export const formatContactData: ContactDataFormatter = (data) => ({
  _id: data._id,
  firstName: data.givenName,
  fathersName: data.surname,
  mothersName: data.surname2,
  email: data.email,
  primaryPhone: {
    number: data.primaryPhone,
    type: data.primaryPhoneType,
    typeShortcut: getPhoneTypeShortcut(data.primaryPhoneType),
  },
  secondaryPhone: {
    number: data.secondaryPhone,
    type: data.secondaryPhoneType,
    typeShortcut: getPhoneTypeShortcut(data.secondaryPhoneType),
  },
  relationship: data.relationship,
  notificationChannel: data.notificationChannel,
});

type NewContactDataFormatter = (data: ContactFormData, filter: ContactFilter) => Partial<ContactFormData>;

export const formatNewContactData: NewContactDataFormatter = (data, filter) => {
  const result: Partial<ContactFormData> = { ...data };

  // Delete secondaryPhone and secondaryPhoneType when it's value is empty ('_!_REMOVE')
  if (isMarkedRemoved(data.secondaryPhone)) {
    delete result.secondaryPhone;
    delete result.secondaryPhoneType;
  }

  // Delete email when it's value is empty ('_!_REMOVE')
  if (isMarkedRemoved(data.email)) {
    delete result.email;
  }

  return {
    ...result,
    contactType: filter.type,
    contactStatus: ContactStatusTypes.Active,
  };
};

export const formatContactFormData = (formData: ContactFormData) => {
  const result: ContactFormData = { ...formData };
  if (isMarkedRemoved(formData.secondaryPhone)) {
    result.secondaryPhoneType = markRemoved(formData.secondaryPhoneType);
  }

  return result;
};
type ContactArrayFormatter = (data: VehicleContactResponse, filter: ContactFilter) => ContactData[];

export const formatContactArray: ContactArrayFormatter = (data, filter) =>
  data.result
    .filter((contact: ContactResponse) => contact.contactType === filter.type)
    .map((contact: ContactResponse) => formatContactData(contact));

type PaymentDataFormatter = (data: PaymentMethodResponse[]) => PaymentMethodData | null;

export const formatPaymentData: PaymentDataFormatter = (data) => {
  if (!Array.isArray(data) || !data.length) {
    return null;
  }

  const paymentData = data?.find((e) => e.defaultPaymentMethod);

  // TODO: Should use latest payment cps api calls and make changes accordingly as part of PORTAL-4573
  if (!paymentData?.cardNumber) {
    return null;
  }

  const { cardHolderInfo, cardNumber, cardType, expirationMonth, expirationYear, paymentMethodId } = paymentData;

  const prettyMonth = `0${expirationMonth}`.slice(-2);

  return {
    cardHolderName: cardHolderInfo?.cardHolderName,
    expirationMonth: prettyMonth,
    cardNumber,
    cardType,
    expirationYear,
    paymentMethodId,
  };
};

type PaymentFormDataFormatter = (data: PaymentMethodData, userId: string) => UpdatePaymentMethodPayload;

export const formatPaymentFormData: PaymentFormDataFormatter = (data, userId) => ({
  userId,
  defaultPaymentMethod: true,
  ...data,
});
