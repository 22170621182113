// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1W0JBd407fQ6kk5sIXfTjg {\n  --color-expiry: var(--theme-color-grey-8a);\n\n  border-bottom: 2px solid var(--theme-color-grey-c1);\n  padding-bottom: 1em;\n  text-align: center;\n}\n\n.g-N0s4nlYBb5nNEqmvRz2 {\n  font-size: 1.1rem;\n  font-weight: bold;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n._376T4FoxreqELIlhUE_6v0 {\n  font-size: 1.1rem;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n.LoOWBjFZsMDMTD65vjYyl {\n  margin-bottom: 0.5em;\n}\n\n._19ZesI9g2PLolQWO0Xtbi0 {\n  font-size: 0.75rem;\n}\n\n._2mQkVVXxMzBXdWlVU_EMOp {\n  color: var(--color-expiry);\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SubscriptionOverview/SubscriptionOverview.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;;EAE1C,mDAAmD;EACnD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":[".SubscriptionOverview {\n  --color-expiry: var(--theme-color-grey-8a);\n\n  border-bottom: 2px solid var(--theme-color-grey-c1);\n  padding-bottom: 1em;\n  text-align: center;\n}\n\n.SubscriptionOverview-vehicle-name {\n  font-size: 1.1rem;\n  font-weight: bold;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n.SubscriptionOverview-vin {\n  font-size: 1.1rem;\n  margin-bottom: 1em;\n  text-transform: uppercase;\n}\n\n.SubscriptionOverview-service {\n  margin-bottom: 0.5em;\n}\n\n.SubscriptionOverview-service-name {\n  font-size: 0.75rem;\n}\n\n.SubscriptionOverview-service-expiry {\n  color: var(--color-expiry);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubscriptionOverview": "_1W0JBd407fQ6kk5sIXfTjg",
	"SubscriptionOverview-vehicle-name": "g-N0s4nlYBb5nNEqmvRz2",
	"SubscriptionOverview-vin": "_376T4FoxreqELIlhUE_6v0",
	"SubscriptionOverview-service": "LoOWBjFZsMDMTD65vjYyl",
	"SubscriptionOverview-service-name": "_19ZesI9g2PLolQWO0Xtbi0",
	"SubscriptionOverview-service-expiry": "_2mQkVVXxMzBXdWlVU_EMOp"
};
export default ___CSS_LOADER_EXPORT___;
