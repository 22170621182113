// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xxvTe1yC1axbupoJWTG4Y {\n  top: 100%;\n  left: 0;\n  width: 100%;\n}\n\n._3n3j2rn5-flJhsOsE3ZOUX {\n  position: relative;\n  padding-left: calc(20px + 1rem);\n}\n\n._3O8MwhZ_UbQHBZhOwph5ai {\n  height: 20px;\n  width: 20px;\n  margin-right: 1rem;\n  position: absolute;\n  left: 0;\n  bottom: 50%;\n  transform: translateY(50%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/WarningBanner.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,OAAO;EACP,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".drop-down {\n  top: 100%;\n  left: 0;\n  width: 100%;\n}\n\n.warning-menu {\n  position: relative;\n  padding-left: calc(20px + 1rem);\n}\n\n.warning-menu-icon {\n  height: 20px;\n  width: 20px;\n  margin-right: 1rem;\n  position: absolute;\n  left: 0;\n  bottom: 50%;\n  transform: translateY(50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop-down": "_3xxvTe1yC1axbupoJWTG4Y",
	"warning-menu": "_3n3j2rn5-flJhsOsE3ZOUX",
	"warning-menu-icon": "_3O8MwhZ_UbQHBZhOwph5ai"
};
export default ___CSS_LOADER_EXPORT___;
