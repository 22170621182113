import React from 'react';
import clsx from 'clsx';
import { ContentfulImage } from '@cv/webframework-react-components';
import { isPaidPackage, isTiered } from '@cv/portal-components-lib';
import useExpireRenewText from '@hooks/useExpireRenewText';
import { isSubscriptionTermMonthly } from '@utils/isSubscriptionTermMonthly';
import { PackageItemContenfulProps, SubscribedPackageWithServices } from './SubscriptionInformation';
import ProductItem from './ProductItem';
import s from './SubscriptionInformation.module.css';
import { isPackageUpcoming } from '@utils/orderPackages';

type PackageItemProps = PackageItemContenfulProps & {
  packageItem: SubscribedPackageWithServices;
};

const PackageItem = ({
  packageItem,
  trialSubscriptionLabel,
  paidSubscriptionLabel,
  startsLabel,
  endsLabel,
  renewsLabel,
  beginsLabel,
  activeIcon,
  upcomingIcon,
  infoIcon,
  renewsAnnually,
  renewsMonthly,
  renewsTermAnuall,
  renewsTermMouth,
  currencyLabels,
  productIcon: serviceIcon,
}: PackageItemProps) => {
  const {
    marketingName,
    products,
    listPrice,
    currency,
    autoRenew,
    termEndDate,
    termStartDate,
    termServiceEndDate,
    termServiceStartDate,
    cancelReceiveDate,
  } = packageItem;

  const isPaid = isPaidPackage(packageItem);
  const isUpcoming = isPackageUpcoming(packageItem);

  const isMonthly = isSubscriptionTermMonthly(packageItem);
  const expireRenewText = useExpireRenewText();
  const packageExpirDate = expireRenewText({
    autoRenew,
    renewLabel: isUpcoming ? beginsLabel : renewsLabel,
    expiresLabel: isUpcoming ? startsLabel : endsLabel,
    isMonthly,
    termEndDate: isUpcoming ? termStartDate : termEndDate,
    termServiceEndDate: isUpcoming ? termServiceStartDate : termServiceEndDate,
    cancelReceiveDate: cancelReceiveDate?.toString(),
  });
  const currencySymbol = currencyLabels[currency.toLowerCase() as Lowercase<Currency>];

  const statusIcon = (isUpcomingItem: boolean): JSX.Element | undefined | null | false => {
    const iconInUse = isUpcomingItem ? upcomingIcon : activeIcon;
    return iconInUse && <ContentfulImage className={s.packageIcon} imageData={iconInUse} />;
  };
  const productIcon = serviceIcon && <ContentfulImage className={s.productIcon} imageData={serviceIcon} />;

  const subtitle = (
    <div className={s.subTitle}>
      <span>
        {isPaid ? (
          <>
            <span>{paidSubscriptionLabel + ': '}</span>
            <span className={s.grayText}>{isMonthly ? renewsMonthly : renewsAnnually}</span>
          </>
        ) : (
          trialSubscriptionLabel
        )}
      </span>
      {isUpcoming && <span>{`${currencySymbol} ${listPrice}/${isMonthly ? renewsTermMouth : renewsTermAnuall}`}</span>}
    </div>
  );

  return (
    <li className={clsx(s.packageItem, isUpcoming && s.upcoming)} key={packageItem.marketingName + '_package'}>
      <div className={s.packageHeading}>
        <h3 className={s.packageTitle}>
          {statusIcon(isUpcoming)}
          {marketingName}
        </h3>
        <time>{packageExpirDate}</time>
      </div>

      <div className={s.subtitleWrapper}>{subtitle}</div>

      <ul className={s.productList}>
        {products.map((product) => {
          const productItemProps = {
            product,
            infoIcon: infoIcon ? <ContentfulImage className={s.infoIcon} imageData={infoIcon} /> : 'i',
            productIcon,
            statusIcon: statusIcon(isUpcoming),
          };
          return <ProductItem {...productItemProps} key={packageItem.marketingName + product.id} />;
        })}
      </ul>
    </li>
  );
};
export default PackageItem;
