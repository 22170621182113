import React from 'react';

import { ComponentMapping } from '@components/component-mappings';
import ContentRenderer from '@components/ContentRenderer';
import useLabels from '@hooks/useLabels';
import { ServerLabel } from '@utils/labels';
import { BrandNamesFilterGroup } from '../../types/BrandNamesFilterGroup';
import useFilterDictionary from './useFilterDictionary';

export type LabelDictionaryProps = {
  name: string;
  component?: ComponentMapping;
  content: ServerLabel[];
  filterGroup?: BrandNamesFilterGroup;
};

function LabelDictionary({ name, component, content, filterGroup }: LabelDictionaryProps): JSX.Element | null {
  const labels = useLabels(content);
  const dictionary = { labels: labels.getAll(), filterGroup };
  const filteredLabels = useFilterDictionary(dictionary);

  if (!component) {
    return null;
  }

  return <ContentRenderer name={name} content={[{ componentType: component, labels: filteredLabels }]} />;
}

export default LabelDictionary;
