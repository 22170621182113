// checking if at least one mil status equals '1' to show warning banner
// update, return amount of mil statuses
const checkWarningStatus = (milStatusData: Array<Object>) => {
  if (!milStatusData) return 0;

  const existingMilTypes = ['LAMP_REQUEST', 'OIL_PRESSURE', 'AIR_BAG', 'BREAK_FLUID', 'ABS', 'TYRE_PRESSURE'];

  const milStatuses = milStatusData.filter((item) => existingMilTypes.includes(item.type));
  const checkWarning = milStatuses.filter((item) => item.status === '1');

  return checkWarning.length;
};

export default checkWarningStatus;
