import React, { MouseEvent } from 'react';
import { ContentfulImage } from '@cv/webframework-react-components';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import styles from './Header.module.css';

type HeaderLogoProps = {
  logo: ContentfulFile;
  url?: string;
  pagesToHideTcLinkInUserDropDown?: string[];
};

const LogoImage = ({ logo }: { logo: ContentfulFile }) => (
  <ContentfulImage className={styles['Header-brand-img']} imageData={logo} />
);

export default function HeaderLogo({ logo, url, pagesToHideTcLinkInUserDropDown }: HeaderLogoProps): JSX.Element {
  if (!url) {
    return <LogoImage logo={logo} />;
  }

  const onLogoClick = (event: MouseEvent) => {
    event.preventDefault();

    if (!pagesToHideTcLinkInUserDropDown?.includes(window.location.pathname)) {
      const win = window.open(url, '_blank');
      win?.focus();
    } else {
      window.location.replace(url);
    }
  };

  return (
    <a href={url} onClick={onLogoClick} rel="noopener noreferrer">
      <LogoImage logo={logo} />
    </a>
  );
}
