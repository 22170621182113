import React, { useState } from 'react';
import styles from './WarningBanner.module.css';
import clsx from 'clsx';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import { useSelector } from 'react-redux';
import checkWarningStatus from '@utils/warning-status-checker';

import WarningIcon from '@assets/x-status.png';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export type WarningBannerProps = {
  name: string;
  content: Array<ContentfulComponent>;
  warningBannerLabel: string;
};

const WarningBanner = ({ name, content, warningBannerLabel }: WarningBannerProps) => {
  const [isExpanded, toggleExpanded] = useState<boolean>(false);
  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const checkWarning = checkWarningStatus(vehicleStatus?.mil);

  // TODO refactor and make renderChevron as reusable component in the future
  const renderChevron = () => {
    return isExpanded ? (
      <FaChevronUp className={styles['warning-banner-chevron']} onClick={() => toggleExpanded(!isExpanded)} />
    ) : (
      <FaChevronDown className={styles['warning-banner-chevron']} onClick={() => toggleExpanded(!isExpanded)} />
    );
  };

  return (
    <div
      className={clsx({ [styles['warning-banner']]: checkWarning, [styles['warning-banner--hidden']]: !checkWarning })}
    >
      <div className={styles['warning-banner-header']}>
        <div className={styles['warning-banner-header-elements']}>
          <img className={styles['warning-banner-icon']} src={WarningIcon} alt="Warning icon" />
          <span className={styles['warning-banner-text']}>{warningBannerLabel}</span>
        </div>
        {renderChevron()}
      </div>

      {isExpanded && (
        <div className={styles['warning-banner-content']}>
          <ContentRenderer name={name} content={content} />
        </div>
      )}
    </div>
  );
};

export default WarningBanner;
